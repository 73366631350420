module.exports = [{
      plugin: require('/home/travis/build/mromarreyes/DevelopAndGo/front-end/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('/home/travis/build/mromarreyes/DevelopAndGo/front-end/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-132792472-1","head":true},
    },{
      plugin: require('/home/travis/build/mromarreyes/DevelopAndGo/front-end/node_modules/gatsby-plugin-pinterest/gatsby-browser.js'),
      options: {"plugins":[],"tall":true,"round":false},
    },{
      plugin: require('/home/travis/build/mromarreyes/DevelopAndGo/front-end/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/travis/build/mromarreyes/DevelopAndGo/front-end/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
